import styled from "styled-components"

export const Title = styled.h1`
    font-size: 32px;
    color: #00b2e5;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 600px) {
        font-size: 28px;
        margin-bottom: 20px;
    }
    @media (max-width: 360px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
`

export const ContainerLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;

    @media (max-width: 600px) {
        margin-top: 50px;
    }
    @media (max-width: 360px) {
        margin-top: 50px;
    }

    img {
        width: 30%;
        margin-bottom: 50px;

        @media (max-width: 600px) {
            width: 80%;
            margin-top: 0px;
        }
        @media (max-width: 360px) {
            width: 80%;
            margin-top: 0px;
        }
    }
`
