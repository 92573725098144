import React, { useState, useEffect, useContext } from "react"
import DatePicker from "react-datepicker"
import { startOfDay, endOfDay, format } from "date-fns"

import "react-datepicker/dist/react-datepicker.css"

import { Container, ContainerInput } from "./style"
import { AuthContext } from "../../context/AuthContext"
import api from "../../services/api"

const Notas = () => {
    const { dominio } = useContext(AuthContext)
    const [startDate, setStartDate] = useState(startOfDay(new Date()))
    const [endDate, setEndDate] = useState(endOfDay(new Date()))
    const [relatorio, setRelatorio] = useState([])
    const [downloadUri, setDownloadUri] = useState("")

    const buscarNotasCallCenter = async () => {
        const { data } = await api.post("/basix/callcenter/notas", {
            domain: dominio,
            start: format(startDate, "dd/MM/yyyy HH:mm:ss"),
            end: format(endDate, "dd/MM/yyyy HH:mm:ss")
        })

        setRelatorio(data)
    }

    useEffect(() => {
        setDownloadUri(
            encodeURI(
                `https://api.cloudcom.com.br/basix/callcenter/notas?domain=${dominio}&start=${format(
                    startDate,
                    "dd/MM/yyyy HH:mm:ss"
                )}&end=${format(endDate, "dd/MM/yyyy HH:mm:ss")}`
            )
        )

        if (startDate && endDate) {
            buscarNotasCallCenter()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate])

    return (
        <Container>
            <ContainerInput>
                <div class="mb-12 row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                        Data Inicial
                    </label>
                    <div class="col-sm-8">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            showTimeInput
                            className="form-control"
                        />
                    </div>
                </div>

                <div class="mb-12 row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                        Data Final
                    </label>
                    <div class="col-sm-8">
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            showTimeInput
                            className="form-control"
                        />
                    </div>
                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                        buscarNotasCallCenter()
                    }}
                >
                    Atualizar
                </button>
                <a href={downloadUri} className="btn btn-primary" target="_blank" rel="noreferrer">
                    Download
                </a>
            </ContainerInput>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Origem</th>
                            <th scope="col">Destino</th>
                            <th scope="col">Estado da Chamada</th>
                            <th scope="col">Numero Discado</th>
                            <th scope="col">Agente</th>
                            <th scope="col">Nota da Pesquisa</th>
                            <th scope="col">Solicitação Atendida</th>
                            <th scope="col">Grupo CallCenter</th>
                            <th scope="col">Inicio</th>
                            <th scope="col">Termino</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio.length > 0 &&
                            relatorio.map((item) => {
                                return (
                                    <tr>
                                        <th scope="row">{item.ORIGEM}</th>
                                        <td>{item.DESTINO}</td>
                                        <td>{item.ESTADO_CHAMADA}</td>
                                        <td>{item.NUMERO_DISCADO}</td>
                                        <td>{item.AGENTE}</td>
                                        <td>{item.NOTA_PESQUISA}</td>
                                        <td>{item.SOLICITACAO_ATENDIDA}</td>
                                        <td>{item.GRUPO}</td>
                                        <td>{item.INICIO}</td>
                                        <td>{item.TERMINO}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </Container>
    )
}

export default Notas
