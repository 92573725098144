import styled from "styled-components"

export const Container = styled.td`
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
    display: block;
    width: 100%;

    .table-responsive {
        margin-left: 5px;
        margin-right: 5px;
    }
`

export const ContainerInput = styled.td`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;

    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
`
