import React, { useContext } from "react";
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

import { AuthContext } from "../context/AuthContext";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
    let signed = false

    const { username, email } = useContext(AuthContext)
    if(username !== null && email !== null){
        signed = true
    }

    if (!signed && isPrivate) {
        return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
        return <Redirect to="/dashboard" />;
    }

    const Layout = signed ? DefaultLayout : AuthLayout;

    return (
        <Route
        {...rest}
        render={(props) => (
            <Layout>
            <Component {...props} />
            </Layout>
        )}
        />
    );
}
