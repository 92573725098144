import React, { createContext, useState, useCallback } from "react"
import { toast } from "react-toastify"

import api from "../services/api"

export const AuthContext = createContext({
    username: null,
    nome: null,
    ramal: null,
    dominio: null,
    logout: null,
    signIn: null
})

export const AuthProvider = ({ children }) => {
    const [username, setUsername] = useState(() => {
        const user = localStorage.getItem("@CloudPanel:username")

        if (user) {
            return user
        }

        return null
    })

    const [nome, setNome] = useState(() => {
        const nome = localStorage.getItem("@CloudPanel:nome")

        if (nome) {
            return nome
        }

        return null
    })

    const [ramal, setRamal] = useState(() => {
        const ramal = localStorage.getItem("@CloudPanel:ramal")

        if (ramal) {
            return ramal
        }

        return null
    })

    const [dominio, setDominio] = useState(() => {
        const dominio = localStorage.getItem("@CloudPanel:dominio")

        if (dominio) {
            return dominio
        }

        return null
    })

    const signIn = useCallback(async ({ user, domain, password }) => {
        try {
            const { data } = await api.post("/basix/auth", {
                user,
                domain,
                password
            })

            localStorage.setItem("@CloudPanel:username", data.USUARIO)
            localStorage.setItem("@CloudPanel:nome", data.NOME)
            localStorage.setItem("@CloudPanel:ramal", data.RAMAL)
            localStorage.setItem("@CloudPanel:dominio", data.DOMINIO)

            setUsername(data.USUARIO)
            setNome(data.NOME)
            setRamal(data.RAMAL)
            setDominio(data.DOMINIO)

            toast.success("Login efetuado com sucesso !!!")
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.error)
            } else {
                toast.error(error.message)
            }
        }
    }, [])

    const logout = useCallback(() => {
        localStorage.clear()
        document.location.reload()
    }, [])

    return (
        <AuthContext.Provider value={{ username, nome, ramal, dominio, logout, signIn }}>
            {children}
        </AuthContext.Provider>
    )
}
