import React from "react"

import Header from "../../../components/Header"

export default function DefaulLayout({ children }) {
    return (
        <>
            <Header />
            {children}
        </>
    )
}
